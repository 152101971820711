import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JWTInterceptorService} from '../jwtinterceptor.service';
import {RefreshJWTInterceptorService} from '../refresh-jwtinterceptor.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, HttpClientModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JWTInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: RefreshJWTInterceptorService, multi: true}
  ]
})
export class TokenModule { }



