import {Injectable} from '@angular/core';

import { Subject } from 'rxjs';

import { UserConfig } from '../entities/UserConfig';
import { Configuration } from '../entities/configuration';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  config$ = new Subject();

  private _config: UserConfig;
  private _configuration: Configuration;

  constructor( ) {
    this._config = new UserConfig();
    this._configuration = new Configuration( {} );
  }

  get configuration(): Configuration {
    return this._configuration;
  }

  set configuration(value) {
    this._configuration = new Configuration(value) ;
  }

  get config() {
    return this._config;
  }

  set config(value) {
    this._config = value;
  }

  get idCommessa() {
    return this._config.idCommessa;
  }

  set idCommessa(value) {
     this._config.idCommessa = value as number;
  }

}
