import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {ConfirmDialogModule} from 'primeng/components/confirmdialog/confirmdialog';
import {ConfirmationService} from 'primeng/components/common/confirmationservice';

import {routing} from './app-routing.module';
import {IsLoggedGuard} from './guards/is-logged.guard';
import {TokenModule} from './services/interceptor/token/token.module';
import {BrowserModule} from '@angular/platform-browser';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    routing,
    FormsModule,
    ConfirmDialogModule,
    TokenModule,  // Modulo di servizio per intercettare il token e il refreshtoken
  ],
  providers: [
    /*
     {provide: HTTP_INTERCEPTORS, useClass: JWTInterceptorService, multi: true},
     {provide: HTTP_INTERCEPTORS, useClass: RefreshJWTInterceptorService, multi: true}, */
    ConfirmationService,
    IsLoggedGuard
  ],
  exports: [
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
