import {Injectable} from '@angular/core';
import {HttpInterceptor} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpEvent} from '@angular/common/http';

import {Observable} from 'rxjs';

import {configuration} from '../../app.component';
import {ConfigService} from '../config.service';

@Injectable({
  providedIn: 'root'
})

export class JWTInterceptorService implements HttpInterceptor {

  constructor(private configService: ConfigService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const refreshToken = sessionStorage.getItem('refreshToken');
    const token = sessionStorage.getItem('token');
    if (token) {
      if (request.url == this.configService.configuration.apiRestLink + 'token' && refreshToken) {
        const newRequest = request.clone({setHeaders: {'Authorization': `Bearer ${refreshToken}`}});

        return next.handle(newRequest);
      } else {
        const newRequest = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }
        });
        return next.handle(newRequest);
      }

    } else {
      return next.handle(request);
    }
  }
}
