
export  class UserConfig {

  private _idCommessa: number;
  private _user: string;
  private _currentUserRolelist: string;
  private _name: string;

  constructor () {}

  get idCommessa(): number {
    return this._idCommessa;
  }

  set idCommessa(value: number) {
    this._idCommessa = value;
  }

  get user(): string {
    return this._user;
  }

  set user(value: string) {
    this._user = value;
  }

  get currentUserRolelist(): string {
    return this._currentUserRolelist;
  }

  set currentUserRolelist(value: string) {
    this._currentUserRolelist = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}
