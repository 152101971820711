import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import {ConfirmationService} from 'primeng/components/common/confirmationservice';


@Injectable()
export class IsLoggedGuard implements CanActivateChild {
  constructor(
    private confService: ConfirmationService,
    private nav: Router
  ) {}

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): boolean {
    if (sessionStorage.getItem('user') || state.url.indexOf('login') > -1) {
      return true;
    } else {
      this.confService.confirm({
        message: 'Per poter visualizzare quanto richiesto devi essere loggato.',
        accept: () => {
          sessionStorage.clear();
          sessionStorage.setItem('url', state.url);
          this.nav.navigate(['/login']);
        }
      });

      return false;
    }
  }
}
