export interface ConfigurationBase {
  geoserver: string;  // baseurl
  postgrest: string;  // link
  geoserver_credential: string;
}

export interface MqttParams {
  host: string;
  port: number;
  path: string;
  username: string;
  password: string;
  protocol;
  client?: string;
}

export class Configuration implements ConfigurationBase {
  apiRestLink?: string;  // link_
  geoserver: string;  // baseurl
  postgrest: string;  // link
  geoserver_credential: string;
  centerLatitude?: number;
  centerLongitude?: number;
  initialZoom?: number;
  imageServer?: string;
  securityLink?: string;
  menuLabel: string[];

  constructor(data) {
    this.apiRestLink = data.apiRestLink;  // link_
    this.geoserver = data.geoserver;  // baseurl
    this.geoserver_credential = data.geoserver_credential;
    this.centerLatitude = data.centerLatitude;
    this.centerLongitude = data.centerLongitude;
    this.initialZoom = data.initialZoom;
    this.imageServer = data.imageServer;
    this.securityLink = data.securityLink;
    this.menuLabel = data.menuLabel;
  }
}
