
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpBackend} from '@angular/common/http';
import {Configuration} from '../entities/configuration';
import {map, tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private https = '';

  constructor(private http: HttpClient,
              private handler: HttpBackend) {
    http = new HttpClient(handler);
    this.https = window.location.href.toString().split(':')[0];
  }

  private httpSwitch(config: any): any {
    let k: keyof typeof config;
    for (k in config) {
      if (typeof config[k] == 'string' && config[k].toString().includes('http')) {
        const test = config[k].toString().split(':')[0];
        if (test != this.https) {
          config[k] = config[k].toString().replace(test, this.https);
        }
      }
    }
    return config;
  }

  getJSONConfiguration(): Observable<Configuration> {
    return this.http.get<Configuration>('./assets/json/configuration.json')
      .pipe( map( res => new Configuration(this.httpSwitch(res)) ) );
      //.pipe( map( res => new Configuration(res))  );
  }

}
