import {Component, OnInit} from '@angular/core';
import {Configuration} from './entities/configuration';
import {ConfigurationService} from './services/configuration.service';
import {ConfigService} from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private configurationService: ConfigurationService,
    private configService: ConfigService ) {

    this.configurationService.getJSONConfiguration()
      .subscribe(
        res => { configuration = res; this.configService.configuration = res; }
      );


  }

  ngOnInit() {
    this.configService.idCommessa = + sessionStorage.getItem('idCommessa');
/*    this.configService.getAllCommesse()
      .subscribe(
        res => console.log(res)
      );*/
  }
}

export let configuration: Configuration = new Configuration({});

